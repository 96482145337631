import { render, staticRenderFns } from "./index.vue?vue&type=template&id=ae3bb460&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=ae3bb460&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae3bb460",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/k8s_dtk_vue_cms_server_pc_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ae3bb460')) {
      api.createRecord('ae3bb460', component.options)
    } else {
      api.reload('ae3bb460', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=ae3bb460&scoped=true&", function () {
      api.rerender('ae3bb460', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/zsz/index.vue"
export default component.exports