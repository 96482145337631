
<!--
 * @Author: your name
 * @Date: 2021-11-08 14:50:49
 * @LastEditTime: 2021-12-01 16:38:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /www/dtk_vue_cms_server_pc_v2/src/pages/ddq/index.vue
-->
<template>
  <div class="zsz-layout">
     <Loading :visible="loading" />
    <div class="zsz-banner">
      <img :src="'https://img.alicdn.com/imgextra/i3/2053469401/O1CN01REHw3h2JJiAVweSNK_!!2053469401.png' || banner" />
    </div>
    <div class="zsz-main">
      <div class="zsz-nav" bi-route="tags">
        <div class="center" ref="zszNavRef">
          <ul>
            <li
              @click="handleTags(index)"
              v-for="(item,index) in navList"
              :key="index"
              :class="`li${index} ${active == index?' active':''}`"
              bi-click="nav"
              :data-cid="item.cid"
              :data-name="item.title"
            >
              <a >
                <i></i>
                {{item.title}}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="top-block" bi-route="goods">
        <p class="tit-h3">
          <i class="iconfont icon-hot"></i>
          <img :src="tagPic" width="135" height="28" alt />
        </p>
        <div class="list_block">
          <div bi-click="list" :data-index="index" :data-cid="cid" :data-gid="item.id || item.goodsId" v-for="(item, index) in renderGoodsTer(navList[active].items,true) " :key="item.id" class="goods_list fl">
            <div class="hot">
              <span>
                <i class="iconfont icon-hot"></i>已售
                <i>{{item.monthSales | salesNum(_,1)}}</i> 件
              </span>
            </div>
            <div class="img-block" @click="handleShowPic(item)" v-marketgroup="item" size="min" >
              <div >
              <img
                v-lazy="`${item.mainPic}_310x310.jpg`"
                width="160"
                height="160"
                alt
                class="fl img"
              />
              </div>
            </div>
            <div class="content">
              <a :href="`?r=/l/d&amp;nav_wrap=zsz&amp;id=${item.id || item.goodsId}`" target="_blank">
                <p class="tit"><i v-if="item.shopType == 1" :class="['tag', item.haitao ? 'tagTmgj' : item.tchaoshi ? 'tagTmc' : 'tagTmail']" :title="item.haitao ? '天猫国际' : item.tchaoshi ? '天猫超市' : '天猫'"></i>{{item.dtitle || item.title}}</p>
              </a>
              <div class="last_time" @click="handleShowPic(item)"></div>
              <div class="pri" @click="handleShowPic(item)" >
                <img
                  :src="zszJu"
                  width="69"
                  height="22"
                  alt
                />
                <span class="tao">
                  ￥
                  <i>{{item.originalPrice}}</i>
                </span>
              </div>
              <div class="quan" @click="handleShowPic(item)">
                <span class="zhj">
                  折后
                  <i>
                    ￥
                    <span>{{item.actualPrice}}</span>
                  </i>
                </span>
                <span class="ticket fr" v-if="item.couponPrice!=0">
                  <i>{{item.couponPrice}}</i>元券
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="zsz-goods" bi-route="goods">
        <div class="goods_cent"  bi-click="list" :data-index="index" :data-cid="cid" :data-gid="item.id || item.goodsId" v-for="(item, index) in renderGoodsTer(navList[active].items) " :key="item.id">
          <div  class="goods_list">
            <div class="img-block"  @click="handleShowPic(item)" >
              <div  v-marketgroup="item" style="width:220px; height:220px;" class="pic-img">
              <img
                width="220"
                height="220"
                alt
                class="fl img"
                v-lazy="`${item.mainPic}_310x310.jpg`"
              />
              </div>
            </div>
            <div class="content">
              <a :href="`?r=/l/d&amp;nav_wrap=zsz&amp;id=${item.id || item.goodsId}`" target="_blank" >
                <p class="tit"><i v-if="item.shopType == 1" :class="['tag', item.haitao ? 'tagTmgj' : item.tchaoshi ? 'tagTmc' : 'tagTmail']" :title="item.haitao ? '天猫国际' : item.tchaoshi ? '天猫超市' : '天猫'"></i>{{item.dtitle || item.title}}</p>
              </a>
              <div class="hot" @click="handleShowPic(item)" >
                <span>
                  <i class="iconfont icon-hot"></i>已售
                  <b>{{item.monthSales | salesNum(_,1)}}</b> 件
                </span>
              </div>
              <div class="last_time" @click="handleShowPic(item)">
                <span style="display: none;">
                  <i class="iconfont icon-time"></i>
                  <span data-time="1636646399">距结束仅剩 12 时 26 分 23 秒</span>
                </span>
              </div>
              <div class="pri" @click="handleShowPic(item)">
                <img
                  :src="zszJu"
                  width="69"
                  height="22"
                  alt
                />
                <span class="tao">
                  ￥
                  <i>{{item.originalPrice}}</i>
                </span>
              </div>
              <div class="quan" @click="handleShowPic(item)">
                <span class="zhj">
                  折后
                  <i>
                    ￥
                    <span>{{item.actualPrice}}</span>
                  </i>
                </span>
                <span class="ticket fr" v-if="item.couponPrice!=0">
                  <i>{{item.couponPrice}}</i>元券
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loading" v-if="isload === active">加载中...</div>
      <intersection-oberver-loading @onScroll="handleIntersection" />
    </div>
    <div
      class="ov-h"
      v-html="`<style>.search-fixed{
          display: none !important;
        }</style>`"
    ></div>
    <MicroDetail @onClose="handleMicroDetailClose" v-if="visible" :visible="visible" :id="microDetailId" />
  </div>
</template>

<script>
import zszJu from './images/zsz_ju.png';
import banner from "./images/zsz_banner.png";
import tagPic from "./images/rqbk.png";
import { mapGetters } from "vuex";
import Loading from "@/components/loading";
import MicroDetail from '@/components/MicroDetail';
import IntersectionOberverLoading from "@/components/IntersectionOberverLoading";
import { importCDN } from "@/utils/utils";
export default {
  data() {
    return {
      zszJu,
      tagPic,
      banner,
        loading: false,
      index: 0,
      active: 0,
      navList: [
        {
          title: "精选",
          cid: '',
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "美食",
          cid: 6,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "美妆",
          cid: 3,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "家具日用",
          cid: 4,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "鞋品",
          cid: 5,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "数码家电",
          cid: 8,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "母婴",
          cid: 2,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "内衣",
          cid: 10,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "文娱车品",
          cid: 7,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "配饰",
          cid: 12,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "家装家纺",
          cid: 14,
          pageId:1,
          items:[],
          totalNum:200,
        },
        {
          title: "户外运动",
          cid: 13,
          pageId:1,
          items:[],
          totalNum:200,
        }
      ],
      items: [],
      gooodsItem: [],
      isload:false,
      visible:false,
      microDetailId:'',
      finish: false,
      cid: ''
    };
  },
  components: {
    MicroDetail,
    IntersectionOberverLoading,
    Loading
  },
  computed: {
    ...mapGetters(["marketGroup", "config"])
  },
  methods: {
    handleShowPic(item){
      if (!this.config.micro_switch) {
          window.open(
          `?r=l/d&u=1&id=${item.id || item.goodsId}&from=dtk`,
          "_blank"
          );
      }else {
          this.microDetailId = item.id || item.goodsId;
          this.visible = true;
      }
    },
    handleMicroDetailClose(){
      this.visible = false;
    },
    renderGoodsTer(items,isHot){
      return isHot ? items.filter((res,key)=>key<3) : items.filter((res,key)=>key>=3) ;
    },
    scrollSearchFixed() {
      const { el, className } = window.ddqScroll;
      if (window.scrollY > 276 && el) {
        el.className =
          className + (className.indexOf("fixed") > -1 ? "" : " fixed");
      } else if (el) {
        el.className = className;
      }
    },
    init() {
      this.getGoodsList();
      window.ddqScroll = {
        el: this.$refs.zszNavRef,
        className: this.$refs.zszNavRef.className
      };
      document.addEventListener("scroll", this.scrollSearchFixed);
    },
    getGoodsList(){
      if (this.loading) {
        return
      }
      const item = this.navList[this.active];
      // 第一页有数据 / pageId 最多请求 5页 / 加载中 / 大于或或等于 不请求接口
      if(item.page == 1 && item.items.length > 0 || this.isload === this.active || item.pageId > 5 || item.items.length >= item.totalNum){
        return false;
      }
      this.loading = true
      this.isload = this.active;
      this.$services.getDTKOpenSuperDiscountGoods({sort:0,pageId:item.pageId,pageSize:item.pageId == 1 ? 23 : 20,cids:item.cid}).then(res=>{
        this.isload = false;
        this.loading = false
        if(res.code != 0) {
          this.finish = true
           return false
        };
        this.navList[this.active].totalNum = res.data.totalNum;
        this.navList[this.active].pageId = parseFloat(res.data.pageId) + 1;
        this.navList[this.active].items = [...this.navList[this.active].items,...res.data.list];
      })
    },
    handleIntersection(){
      if (this.finish) return
      this.getGoodsList();
    },
    handleTags(index) {
      this.active = index;
      const item = this.navList[this.active];
      this.cid = item.cid || item.title
      if(window.scrollY > 276){
        window.scrollTo(0,276);
      }
      this.$router.push({
        path:'/realtime/zsz',
        query:{
          ...{index:this.active}
        }
      })
      // this.$routes.push({
      //   path: '/zsz',
      //   query: {
      //     ...this.$routes.location.data,
      //     ...{index:this.active}
      //   }
      // });
      if(item.items.length == 0){
        this.getGoodsList();
      }
    }
  },
  destroyed() {
    document.removeEventListener("scroll", this.scrollSearchFixed, false);
  },
  mounted() {
    const props = this.$route.query;
    this.active = props.index || 0;
    this.init();
    if(this.config.site_name){
      document.title = `折上折_${this.config.site_name}`;
    }
  }
};
</script>
<style scoped lang="less">
.loading{
  text-align: center; height: 100px; line-height: 100px;  font-size:18px; color: #999;
}
.ov-h {
  display: none;
  clear: both;
}
.zsz-layout {
  background: url(./images/zs_top_bg.png) no-repeat center 120px;
  min-height: 100vh;
  background-size: 1300px 388px;
}
.zsz-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 20px;
  img {
    display: block;
    padding: 0;
    margin: 0;
    height: 84px;
  }
}
.zsz-main {
  width: 1200px;
  margin: auto;

  padding-bottom: 30px;
}
.zsz-nav {
  height: 77px;
  width: 100%;
  position: relative;
  z-index: 200;
  .center {
    text-align: center;
    width: 100%;
    height: 77px;
    &.fixed {
      position: fixed;
      background: #f03a6e;
      left: 0;
      top: 0;
      z-index: 200;
    }
    ul {
      width: 1200px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 77px;
      margin: auto;
      li {
        color: #fff;
        opacity: 0.7;
        text-align: center;
        font-size: 14px;
        padding: 0 20px;
        position: relative;
        cursor: pointer;
        z-index: 0;
        &::before {
          transition: all 0.4s ease;
          content: "";
          position: absolute;
          border: 2px solid #fffd46;
          border-radius: 2px;
          left: 50%;

          bottom: -15px;
          width: 0;
          opacity: 0;
        }
        a {
          color: #fff;
        }
        &.active,
        &:hover {
          opacity: 1;
        }
        &.active {
          &::before {
            width: 26px;
            margin-left: -13px;
            opacity: 1;
          }
        }
        i {
          width: 25px;
          height: 25px;
          background: url(./images/topico0.png) no-repeat;
          background-size: 25px 25px;
          display: block;
          margin: auto;
          margin-bottom: 5px;
        }

        &.li1 {
          i {
            background-image: url(./images/topico6.png);
          }
        }
        &.li2 {
          i {
            background-image: url(./images/topico3.png);
          }
        }
        &.li3 {
          i {
            background-image: url(./images/topico4.png);
          }
        }
        &.li4 {
          i {
            background-image: url(./images/topico5.png);
          }
        }
        &.li5 {
          i {
            background-image: url(./images/topico8.png);
          }
        }
        &.li6 {
          i {
            background-image: url(./images/topico2.png);
          }
        }
        &.li7 {
          i {
            background-image: url(./images/topico7.png);
          }
        }
        &.li8 {
          i {
            background-image: url(./images/topico12.png);
          }
        }
        &.li9 {
          i {
            background-image: url(./images/topico14.png);
          }
        }
        &.li10 {
          i {
            background-image: url(./images/topico13.png);
          }
        }
      }
    }
  }
}

.tit-h3 {
  position: relative;
  padding: 30px 0 30px 0;
  text-align: center;
}
.tit-h3 .icon-hot {
  font-size: 29px;
  color: #fcff00;
  position: relative;
  top: -4px;
}
.list_block {
  height: 250px;
}
.list_block i {
  font-style: normal;
}
.pic-img{
  overflow: hidden; border-radius: 10px;
}
.goods_list {
  
  .img-block .img{

    transition: -moz-transform .5s;
  }
  &:hover{
    .img-block .img{transform: scale(1.04);}
    .tit{color:#FF2B22 !important;}
  }
    
}
.list_block .goods_list {
  width: 393px;
  height: 220px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  display: block;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 11px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.list_block .goods_list .hot {
  padding: 3px 0;
  margin-bottom: 10px;
}
.list_block .goods_list .hot span {
  height: 22px;
  line-height: 22px;
  background: rgba(244, 96, 97, 1);
  border-radius: 13px;
  color: #fff;
  padding: 3px 10px;
}
.list_block .goods_list .hot .iconfont {
  color: #fff;
}
.list_block .goods_list .content {
  display: inline-block;
  margin-left: 13px;
  width: 195px;
}
.list_block .goods_list .content .tit {
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  height: 44px;
  line-height: 22px;
  text-align: left;
  margin: 0;
  padding: 0;
}
.list_block .goods_list .content .last_time {
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  height: 26px;
  margin: 5px 0;
}
.list_block .goods_list .content .last_time .iconfont {
  font-size: 20px;
  position: relative;
  top: 2px;
}
.list_block {
  width: 1212px;
}
.list_block .goods_list .content .pri,
.goods_cent .goods_list .pri {
  height: 22px;
  line-height: 22px;
  position: relative;
}
.list_block .goods_list .content .pri span,
.goods_cent .goods_list .pri span {
  position: absolute;
  width: 69px;
  height: 20px;
  text-align: center;
  border: 1px solid #f03a6e;
  color: #f03a6e;
}
.list_block .goods_list .content .pri span.tao,
.goods_cent .goods_list .pri span.tao {
  border: 1px solid #fc353a;
  color: #fc353a;
}
.list_block .goods_list .content .quan,
.goods_cent .goods_list .quan {
  margin: 18px 0 0;
  display: flex;
  align-items: center;
}
.list_block .goods_list .content .quan .zhj,
.goods_cent .goods_list .quan .zhj {
  font-size: 14px;
  color: rgba(153, 153, 153, 1);
}
.list_block .goods_list .content .quan .zhj i,
.goods_cent .goods_list .quan .zhj i {
  font-size: 22px;
  font-weight: bold;
  font-family: Arial;
  color: rgba(255, 43, 34, 1);
  line-height: 30px;
}
.list_block .goods_list .content .quan .ticket,
.goods_cent .goods_list .quan .ticket {
  height: 24px;
  line-height: 22px;
  background: url(./images/quan.png) center #fff8f8;
  background-size: 100% 100%;
  border-radius: 2px;
  padding: 2px 10px;
  position: relative;
  min-width: 57px;
  box-sizing: border-box;
  margin-left: 10px;
  color: #ff2b22;
}
.fl {
  float: left;
}

.zsz-goods {
  background: #fff;
  overflow: visible;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  clear: both;
}

.goods_cent {
  width: 600px;
  height: 260px;
  padding: 0 0 0 20px;
  box-sizing: border-box;
  float: left;
  .goods_list{
    display: flex !important;
  }
  p {
    margin: 0;
  }
  i {
    font-style: normal;
  }
  
}
.tit .tagTmail {
    background: url(./images/tmall.png) center no-repeat;
    background-size: contain;
}
.tit .tagTmgj {
    background: url(./images/tmgj.png) center no-repeat;
    background-size: contain;
}
.tit .tagTmc {
    background: url(./images/maoc.png) center no-repeat;
    background-size: contain;
}
.tit .tag {
    height: 18px;
    width: 15px;
    float: left;
    margin: 0 5px 0 0;
}

.goods_cent:nth-child(odd) .goods_list .content {
  width: 329px;
  height: 220px;
  margin-left: 30px;
  display: inline-block;
  padding-right: 20px;
  box-sizing: border-box;
  border-right: 1px solid #e9e9e9;
}
.goods_cent:nth-child(even) {
  padding-left: 0;
}
.goods_cent:nth-child(even) .goods_list {
  padding-left: 20px;
}
.goods_cent .goods_list {
  display: block;
  height: 260px;
  padding: 20px 0;
  box-sizing: border-box;
  border-top: 1px solid #e9e9e9;
  cursor: pointer;
}
.goods_cent:nth-child(1) .goods_list,
.goods_cent:nth-child(2) .goods_list {
  border: none;
}
.list_block .goods_list .img-block,
.zs_list_block .goods_list .img-block {
  overflow: hidden;
  width: 160px;
  height: 160px;
  float: left;
  border-radius: 10px; 
}
.zs_list_block .goods_list .img-block {
  width: 220px;
  height: 220px;
  position: relative;
}
.goods_list .img {
  border-radius: 10px;
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
}
.goods_cent .goods_list .content {
  width: 300px;
  margin-left: 30px;
  display: inline-block;
}
.goods_cent .goods_list .tit {
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 20px 0 0 0;
}
.goods_cent .goods_list .content .hot {
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
  margin: 15px 0 0;
}
.goods_cent .goods_list .content .hot .iconfont {
  color: #fc353a;
  margin-left: -2px;
}
.goods_cent .goods_list .content .hot b {
  color: #fc353a;
  font-weight: normal;
}
.goods_cent .goods_list .content .last_time {
  margin: 15px 0 10px 0;
  height: 33px;
}
.goods_cent .goods_list .content .last_time .iconfont {
  position: relative;
  top: 3px;
  margin-right: 3px;
  margin-left: -2px;
  font-size: 22px;
}
.goods_cent .goods_list .content .quan .zhj i {
  font-size: 24px;
}
</style>