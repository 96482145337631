<!--
 * @Author: your name
 * @Date: 2021-08-12 09:15:44
 * @LastEditTime: 2021-08-12 11:05:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/dtk_vue_cms_server_v2/src/components/IntersectionOberverLoading/index.vue
-->
<template>
  <div
    ref="refIntersectionObserver"
    class="intersection-observer"
    :style="`${styles ? styles : ''}`"
  ></div>
</template>

<script>
import "intersection-observer";
export default {
  name: "intersection-oberver-loading",
  props: {
    onScroll: Function, // 到底部回调
    styles: String,
    defaultShowServer: Boolean
  },
  methods: {
    intersectionObserverFuc() {
      const _this = this;
      if (!window.IntersectionObserver) {
        return false;
      }
      let isShowServer = this.defaultShowServer;
      const intersectionObserver = new IntersectionObserver(function(entries) {
        entries.forEach(function(element, index) {
          if (element.isIntersecting && isShowServer) {
            _this.$emit("onScroll", this);
          } else {
            isShowServer = true;
          }
        });
      });

      intersectionObserver.observe(this.$refs.refIntersectionObserver);
    },
  },
  mounted() {
    this.intersectionObserverFuc();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.intersection-observer {
  overflow: hidden;
  position: relative;
  top: -30vh;
  z-index: 99;
  height: 10px;
}
</style>
