var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "zsz-layout" },
    [
      _c("Loading", { attrs: { visible: _vm.loading } }),
      _c("div", { staticClass: "zsz-banner" }, [
        _c("img", {
          attrs: {
            src:
              "https://img.alicdn.com/imgextra/i3/2053469401/O1CN01REHw3h2JJiAVweSNK_!!2053469401.png" ||
              _vm.banner
          }
        })
      ]),
      _c(
        "div",
        { staticClass: "zsz-main" },
        [
          _c("div", { staticClass: "zsz-nav", attrs: { "bi-route": "tags" } }, [
            _c("div", { ref: "zszNavRef", staticClass: "center" }, [
              _c(
                "ul",
                _vm._l(_vm.navList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class:
                        "li" +
                        index +
                        " " +
                        (_vm.active == index ? " active" : ""),
                      attrs: {
                        "bi-click": "nav",
                        "data-cid": item.cid,
                        "data-name": item.title
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleTags(index)
                        }
                      }
                    },
                    [_c("a", [_c("i"), _vm._v(" " + _vm._s(item.title) + " ")])]
                  )
                }),
                0
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "top-block", attrs: { "bi-route": "goods" } },
            [
              _c("p", { staticClass: "tit-h3" }, [
                _c("i", { staticClass: "iconfont icon-hot" }),
                _c("img", {
                  attrs: {
                    src: _vm.tagPic,
                    width: "135",
                    height: "28",
                    alt: ""
                  }
                })
              ]),
              _c(
                "div",
                { staticClass: "list_block" },
                _vm._l(
                  _vm.renderGoodsTer(_vm.navList[_vm.active].items, true),
                  function(item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "goods_list fl",
                        attrs: {
                          "bi-click": "list",
                          "data-index": index,
                          "data-cid": _vm.cid,
                          "data-gid": item.id || item.goodsId
                        }
                      },
                      [
                        _c("div", { staticClass: "hot" }, [
                          _c("span", [
                            _c("i", { staticClass: "iconfont icon-hot" }),
                            _vm._v("已售 "),
                            _c("i", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("salesNum")(item.monthSales, _vm._, 1)
                                )
                              )
                            ]),
                            _vm._v(" 件 ")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "marketgroup",
                                rawName: "v-marketgroup",
                                value: item,
                                expression: "item"
                              }
                            ],
                            staticClass: "img-block",
                            attrs: { size: "min" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowPic(item)
                              }
                            }
                          },
                          [
                            _c("div", [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: item.mainPic + "_310x310.jpg",
                                    expression: "`${item.mainPic}_310x310.jpg`"
                                  }
                                ],
                                staticClass: "fl img",
                                attrs: { width: "160", height: "160", alt: "" }
                              })
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "?r=/l/d&nav_wrap=zsz&id=" +
                                  (item.id || item.goodsId),
                                target: "_blank"
                              }
                            },
                            [
                              _c("p", { staticClass: "tit" }, [
                                item.shopType == 1
                                  ? _c("i", {
                                      class: [
                                        "tag",
                                        item.haitao
                                          ? "tagTmgj"
                                          : item.tchaoshi
                                          ? "tagTmc"
                                          : "tagTmail"
                                      ],
                                      attrs: {
                                        title: item.haitao
                                          ? "天猫国际"
                                          : item.tchaoshi
                                          ? "天猫超市"
                                          : "天猫"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(_vm._s(item.dtitle || item.title))
                              ])
                            ]
                          ),
                          _c("div", {
                            staticClass: "last_time",
                            on: {
                              click: function($event) {
                                return _vm.handleShowPic(item)
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "pri",
                              on: {
                                click: function($event) {
                                  return _vm.handleShowPic(item)
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.zszJu,
                                  width: "69",
                                  height: "22",
                                  alt: ""
                                }
                              }),
                              _c("span", { staticClass: "tao" }, [
                                _vm._v(" ￥ "),
                                _c("i", [_vm._v(_vm._s(item.originalPrice))])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "quan",
                              on: {
                                click: function($event) {
                                  return _vm.handleShowPic(item)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "zhj" }, [
                                _vm._v(" 折后 "),
                                _c("i", [
                                  _vm._v(" ￥ "),
                                  _c("span", [_vm._v(_vm._s(item.actualPrice))])
                                ])
                              ]),
                              item.couponPrice != 0
                                ? _c("span", { staticClass: "ticket fr" }, [
                                    _c("i", [_vm._v(_vm._s(item.couponPrice))]),
                                    _vm._v("元券 ")
                                  ])
                                : _vm._e()
                            ]
                          )
                        ])
                      ]
                    )
                  }
                ),
                0
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "zsz-goods", attrs: { "bi-route": "goods" } },
            _vm._l(_vm.renderGoodsTer(_vm.navList[_vm.active].items), function(
              item,
              index
            ) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "goods_cent",
                  attrs: {
                    "bi-click": "list",
                    "data-index": index,
                    "data-cid": _vm.cid,
                    "data-gid": item.id || item.goodsId
                  }
                },
                [
                  _c("div", { staticClass: "goods_list" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img-block",
                        on: {
                          click: function($event) {
                            return _vm.handleShowPic(item)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "marketgroup",
                                rawName: "v-marketgroup",
                                value: item,
                                expression: "item"
                              }
                            ],
                            staticClass: "pic-img",
                            staticStyle: { width: "220px", height: "220px" }
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: item.mainPic + "_310x310.jpg",
                                  expression: "`${item.mainPic}_310x310.jpg`"
                                }
                              ],
                              staticClass: "fl img",
                              attrs: { width: "220", height: "220", alt: "" }
                            })
                          ]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "?r=/l/d&nav_wrap=zsz&id=" +
                              (item.id || item.goodsId),
                            target: "_blank"
                          }
                        },
                        [
                          _c("p", { staticClass: "tit" }, [
                            item.shopType == 1
                              ? _c("i", {
                                  class: [
                                    "tag",
                                    item.haitao
                                      ? "tagTmgj"
                                      : item.tchaoshi
                                      ? "tagTmc"
                                      : "tagTmail"
                                  ],
                                  attrs: {
                                    title: item.haitao
                                      ? "天猫国际"
                                      : item.tchaoshi
                                      ? "天猫超市"
                                      : "天猫"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(_vm._s(item.dtitle || item.title))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "hot",
                          on: {
                            click: function($event) {
                              return _vm.handleShowPic(item)
                            }
                          }
                        },
                        [
                          _c("span", [
                            _c("i", { staticClass: "iconfont icon-hot" }),
                            _vm._v("已售 "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("salesNum")(item.monthSales, _vm._, 1)
                                )
                              )
                            ]),
                            _vm._v(" 件 ")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "last_time",
                          on: {
                            click: function($event) {
                              return _vm.handleShowPic(item)
                            }
                          }
                        },
                        [_vm._m(0, true)]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pri",
                          on: {
                            click: function($event) {
                              return _vm.handleShowPic(item)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.zszJu,
                              width: "69",
                              height: "22",
                              alt: ""
                            }
                          }),
                          _c("span", { staticClass: "tao" }, [
                            _vm._v(" ￥ "),
                            _c("i", [_vm._v(_vm._s(item.originalPrice))])
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "quan",
                          on: {
                            click: function($event) {
                              return _vm.handleShowPic(item)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "zhj" }, [
                            _vm._v(" 折后 "),
                            _c("i", [
                              _vm._v(" ￥ "),
                              _c("span", [_vm._v(_vm._s(item.actualPrice))])
                            ])
                          ]),
                          item.couponPrice != 0
                            ? _c("span", { staticClass: "ticket fr" }, [
                                _c("i", [_vm._v(_vm._s(item.couponPrice))]),
                                _vm._v("元券 ")
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          ),
          _vm.isload === _vm.active
            ? _c("div", { staticClass: "loading" }, [_vm._v("加载中...")])
            : _vm._e(),
          _c("intersection-oberver-loading", {
            on: { onScroll: _vm.handleIntersection }
          })
        ],
        1
      ),
      _c("div", {
        staticClass: "ov-h",
        domProps: {
          innerHTML: _vm._s(
            "<style>.search-fixed{\n        display: none !important;\n      }</style>"
          )
        }
      }),
      _vm.visible
        ? _c("MicroDetail", {
            attrs: { visible: _vm.visible, id: _vm.microDetailId },
            on: { onClose: _vm.handleMicroDetailClose }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { display: "none" } }, [
      _c("i", { staticClass: "iconfont icon-time" }),
      _c("span", { attrs: { "data-time": "1636646399" } }, [
        _vm._v("距结束仅剩 12 时 26 分 23 秒")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }