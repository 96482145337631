var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "refIntersectionObserver",
    staticClass: "intersection-observer",
    style: "" + (_vm.styles ? _vm.styles : "")
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }